import * as React from 'react';
import * as S from './style';
import styled from 'styled-components';
import { useMemo } from 'react';
import Link from 'next/link';
import { StyledRelinkItemBanner } from './style';
import { useStore } from '@stores/root';

type Props = {
  item: ExternalProductRelink;
  className?: string;
};

const clickableTextTypes = new Set<ExternalProductRelink['type']>(['here', 'buy', 'link']);

const Component = (props: Props) => {
  const { item } = props;

  const url = `https://nyagan.${item.domain}${item.url}`;

  const descText = useMemo(() => {
    let result = '';

    switch (item.type) {
      case 'here':
        result = 'Тут';
        break;

      case 'buy':
        result = 'Хочу купить';
        break;

      default:
        result = url;
        break;
    }

    return result;
  }, [item.type]);

  return (
    <S.StyledRelinkItem className={props.className}>
      <S.StyledRelinkItemBanner>Реклама</S.StyledRelinkItemBanner>
      <S.StyledRelinkItemLeft>
        <S.StyledRelinkItemImage>
          {wrap(<img src={item.image_url} alt={item.text} />, item.type === 'image' && url)}
        </S.StyledRelinkItemImage>
      </S.StyledRelinkItemLeft>
      <S.StyledRelinkItemRight>
        {wrap(
          <S.StyledRelinkItemName>{item.text}</S.StyledRelinkItemName>,
          item.type === 'anchor' && url
        )}
        {wrap(
          <S.StyledRelinkItemDescription>{descText}</S.StyledRelinkItemDescription>,
          clickableTextTypes.has(item.type) && url
        )}
      </S.StyledRelinkItemRight>
    </S.StyledRelinkItem>
  );
};

const wrap = (component: JSX.Element, href?: string) => {
  if (href) {
    return (
      <Link href={href}>
        {component}
      </Link>
    );
  }

  return component;
};

export const RelinkItem = styled(Component)``;
